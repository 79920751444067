import axios from "axios"
import { z } from "zod"
import { create } from "zustand"
import { persist } from "zustand/middleware"
import { authenticate, authorizeUserSchema, logout, refreshAccessToken } from "../utils/AuthClient"
import { showWarning } from "../utils/ErrorUtils"
import { JwtInfoModel, parseJwt } from "../utils/PermissionCodes"

export interface LoginCredentials {
  username: string
  password: string
  oneTimePasscode?: string
}

interface MfaCachedCredentials extends LoginCredentials {
  sid: string
  email: string
}

const mfaCodeSentSchema = z.object({
  category: z.literal("MFA"),
  message: z.literal("OneTimePasscode Sent"),
  channel: z.literal("Email"),
  to: z.string(),
  sid: z.string(),
})

const authenticationStates = z.enum(["authenticated", "mfa-code-sent", "mfa-code-verified", "unauthenticated"])
type AuthenticationState = z.infer<typeof authenticationStates>

interface CurrentUser extends JwtInfoModel {
  accessToken: string
  refreshToken: string
}

interface AuthState {
  authState: AuthenticationState
  authError?: any
  // MFA Requires resending the credentials, so we need to store them if mfa is reqested.
  mfaCachedCredentials?: MfaCachedCredentials
  currentUser?: CurrentUser
}

interface AuthActions {
  login: (credentials: any) => Promise<void>
  loginSSO: (accessToken: string, refreshToken: string) => Promise<void>
  verifyMfaCode: (mfaCode: string) => Promise<void>
  rememberMfaDevice: () => Promise<void>
  skipRememberMfaDevice: () => Promise<void>
  refreshAccessToken: () => Promise<void>
  logout: ({ isTimeout }: { isTimeout?: boolean }) => Promise<void>
}

const initialAuthState: AuthState = {
  authState: "unauthenticated",
  authError: undefined,
  mfaCachedCredentials: undefined,
  currentUser: undefined,
}

export const useAuthStore = create<AuthState & AuthActions>()(
  persist(
    (set, get) => ({
      ...initialAuthState,
      login: async (credentials: LoginCredentials) => {
        try {
          const { accessToken, refreshToken } = await authenticate(credentials)
          const decodedValue = parseJwt(accessToken)

          set({
            authState: "authenticated",
            currentUser: {
              ...decodedValue,
              accessToken,
              refreshToken,
            },
            authError: undefined,
            mfaCachedCredentials: undefined,
          })
        } catch (e) {
          if (axios.isAxiosError(e)) {
            const result = mfaCodeSentSchema.safeParse(e.response?.data)

            if (!result.success) {
              set({
                authError: e,
              })
              return
            }

            const mfaSentResponse = result.data

            set({
              authState: "mfa-code-sent",
              mfaCachedCredentials: {
                ...credentials,
                sid: mfaSentResponse.sid,
                email: mfaSentResponse.to,
              },
              authError: undefined,
            })
          } else {
            set({
              authError: e,
            })
          }
        }
      },
      loginSSO: async (accessToken: string, refreshToken: string) => {
        const decodedValue = parseJwt(accessToken)

        set({
          authState: "authenticated",
          currentUser: {
            ...decodedValue,
            accessToken,
            refreshToken,
          },
          authError: undefined,
          mfaCachedCredentials: undefined,
        })
      },
      verifyMfaCode: async (mfaCode: string) => {
        const { mfaCachedCredentials } = get()
        if (!mfaCachedCredentials) {
          throw new Error("No cached credentials found.")
        }

        const { sid, email, ...credentials } = mfaCachedCredentials

        try {
          const { accessToken, refreshToken } = await authenticate({ ...credentials, oneTimePasscode: mfaCode })
          const decodedValue = parseJwt(accessToken)

          set({
            authState: "mfa-code-verified",
            currentUser: {
              ...decodedValue,
              accessToken,
              refreshToken,
            },
            authError: undefined,
            mfaCachedCredentials: undefined,
          })
        } catch (e) {
          set({
            authError: e,
          })
        }
      },
      rememberMfaDevice: async () => {
        const { currentUser } = get()

        if (!currentUser) {
          throw new Error("No current user found.")
        }

        const { UserName, accessToken } = currentUser

        const mfaTokens = new Map<string, string>(JSON.parse(window.localStorage.getItem("mfa-tokens") || "[]"))
        mfaTokens.set(UserName, accessToken)
        localStorage.setItem("mfa-tokens", JSON.stringify(Array.from(mfaTokens.entries())))

        set({
          authState: "authenticated",
          authError: undefined,
          mfaCachedCredentials: undefined,
        })
      },
      skipRememberMfaDevice: async () => {
        set({
          authState: "authenticated",
          authError: undefined,
          mfaCachedCredentials: undefined,
        })
      },
      refreshAccessToken: async () => {
        const { currentUser } = get()

        if (!currentUser) {
          throw new Error("No current user found.")
        }

        for (let i = 0; i < 3; i++) {
          const delayPromise = new Promise((resolve) => setTimeout(resolve, 10000))
          try {
            const accessTokenResult = await refreshAccessToken(currentUser.refreshToken)
            if (accessTokenResult.status === 401) {
              break
            }
            const accessTokenData = authorizeUserSchema.parse(accessTokenResult.data)
            const { accessToken, refreshToken } = accessTokenData

            const decodedValue = parseJwt(accessToken)

            set({
              currentUser: {
                ...decodedValue,
                accessToken,
                refreshToken,
              },
            })
            return
          } catch {
            await delayPromise
          }
        }

        try {
          await logout({ isTimeout: true })
          showWarning("You have been logged out because your session has expired.")
        } catch (e) {
          console.error(e)
        }
        set(initialAuthState)
      },
      logout: async ({ isTimeout = false }) => {
        try {
          await logout({ isTimeout })
        } catch (e) {
          console.error(e)
        }
        set(initialAuthState)
      },
    }),
    {
      name: "auth",
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(([key]) => !["authError", "mfaCachedCredentials"].includes(key)),
        ),
    },
  ),
)

export const useViewPermission = (permissionCodes?: number[]) => {
  const userPermissions = useAuthStore((state) => state.currentUser?.PermissionCodes)

  return hasAllPermissions({ userPermissions, requiredPermissions: permissionCodes })
}

export const hasViewPermission = (permissionCodes?: number[]) => {
  const userPermissions = useAuthStore.getState().currentUser?.PermissionCodes

  return hasAllPermissions({ userPermissions, requiredPermissions: permissionCodes })
}

export const hasAllPermissions = ({
  userPermissions,
  requiredPermissions,
}: {
  userPermissions?: number[]
  requiredPermissions?: number[]
}) => {
  if (!requiredPermissions || requiredPermissions?.length === 0) {
    return true
  }

  if (!userPermissions) {
    return false
  }

  return requiredPermissions?.every((code: number) => permissionInList(userPermissions, code))
}

export const hasPermission = (permissionCode: number) => {
  const userPermissions = useAuthStore.getState().currentUser?.PermissionCodes

  if (!userPermissions) {
    return false
  }

  return permissionInList(userPermissions, permissionCode)
}

const permissionInList = (userPermissions: number[], permission: number) => {
  return userPermissions.includes(permission)
}

export const useIsAuthenticated = () => {
  return useAuthStore((state) => state.authState === "authenticated")
}

export const useAuth = () => {
  return {}
}
