import vanillaClient from "@/src/utils/AxiosClient"
import { Metric } from "web-vitals"

const _errorUrl = "/monitoring"

const logWebVitals = (content: Metric) => {
  return vanillaClient<any>(`${_errorUrl}/logWebVitals`, {
    body: { ...content, entries: JSON.stringify(content.entries) },
    method: "POST",
  })
}

const logError = (message: string, details: any) => {
  return vanillaClient<any>(`${_errorUrl}/logError`, {
    body: {
      message: message,
      pathName: window.location.pathname,
      details: JSON.stringify(details),
    },
    method: "POST",
  })
}

export { logWebVitals, logError }
