import { Button } from "@/components/ui/button"
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { SelectItem } from "@/components/ui/select"
import { StyledLink } from "@/components/ui/styled-link"
import { Table, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { cn } from "@/lib/utils"
import { faSortDown, faSortUp } from "@fortawesome/pro-duotone-svg-icons"
import { faSort } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react"
import { useForm, useWatch } from "react-hook-form"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import * as portals from "react-reverse-portal"
import { useHistory } from "react-router-dom"
import { create } from "zustand"
import { useBulkActionPortal } from "../../context/BulkActionPortalContext"
import { ThruFormProvider } from "../../context/ThruFormContext"
import { joinIds } from "../../utils/IdUtil"
import usePageCount from "../../utils/usePageCount"
import { BulkActionsBar } from "../bulk-actions/BulkActionsBar"
import Fade from "../fade/Fade"
import SelectInput from "../form/SelectInput"
import TextInput from "../form/TextInput"
import ListCancelButton from "../list-cancel-button/ListCancelButton"
import PaginationBar from "../pagination-bar/PaginationBar"
import RefreshIcon from "../refresh-icon/RefreshIcon"
import RowActions, { ActionItem } from "../row-options/RowActions"
import Tooltip from "../tooltip/Tooltip"

export const getLocalStoragePageSize = () => {
  const pageSize = localStorage.getItem("pageSize")
  return pageSize ? +pageSize : undefined
}

const setLocalStoragePageSize = (pageSize: number) => {
  localStorage.setItem("pageSize", pageSize.toString())
}

interface BulkActionStore {
  active: boolean
  reset: () => void
  setActive: (active: boolean) => void
}

export const useBulkActions = create<BulkActionStore>()((set, get) => ({
  active: false,
  setActive: (active: boolean) => set({ active }),
  reset: () => {
    set((state) => {
      return { active: false }
    })
  },
}))

type SortColumn = {
  index: number
  key: string
  ascending: boolean
}

const getLocalStorageSortColumn = (path: string) => {
  const oldSortColumn = localStorage.getItem("sortColumn")

  if (!oldSortColumn) {
    return undefined
  }

  return JSON.parse(oldSortColumn)[path] as SortColumn
}

const setLocalStorageSortColumn = (path: string, sortColumn: SortColumn) => {
  const oldSortColumn = localStorage.getItem("sortColumn") ?? "{}"

  localStorage.setItem("sortColumn", JSON.stringify({ ...JSON.parse(oldSortColumn), [path]: sortColumn }))
}

const clearLocalStorageSortColumn = (path: string) => {
  const oldSortColumn = localStorage.getItem("sortColumn") ?? "{}"
  const sortColumn = JSON.parse(oldSortColumn)

  delete sortColumn[path]

  localStorage.setItem("sortColumn", JSON.stringify(sortColumn))
}

export interface IColumn<T> {
  title:
    | ((sortKey: string) => PropsWithChildren<ReactNode> | number | null)
    | PropsWithChildren<ReactNode>
    | number
    | null
  to?: (data: T) => string | undefined
  getValue: (data: T) => ReactNode | number | null
  getSortKeys?: () => string[]
  getSortValue?: (data: T, sortKey: string) => string | number | undefined
  compare?: (sortKey: string) => ((val1: string, val2: string, ascending: boolean) => number) | undefined
  registerOnForm?: boolean
  watchName?: string
  disableSorting?: boolean
  allowOverflow?: boolean
  removeTdPaddingLeft?: boolean
  removeTdPadding?: boolean
  thClass?: string
  tdClass?: string
}

type ISearchableTable<T> = {
  header: IColumn<T>[]
  getRowId: (rowData: T, index: number) => number | string
  createButton?: ReactNode | ReactNode[]
  cancelButton?: string
  filterButton?: ReactNode | ReactNode[]
  actions?: ((data: T) => ActionItem<T>[]) | ActionItem<T>[]
  refresh?: () => void
  data?: T[]
  pageSize?: number
  customTableClass?: string
  getSearchableContent?: (data: T) => string[]
  getSearchableContentExact?: (data: T) => string[]
  label?: ReactNode
  customClass?: string
  onFormSubmit?: (data: any) => void
  customForm?: any
  idPrefix: string
  isFetching?: boolean
  mainCheckbox?: string
  defaultSortDirection?: "ASC" | "DESC"
  defaultSortColumn?: number
  autoScrollX?: boolean
  searchDisabled?: boolean
  paginationDisabled?: boolean
  fillLine?: boolean
  setPageOverride?: Dispatch<SetStateAction<number>>
  pageOverride?: number
  pagesOverride?: number
  totalOverride?: number
  autoFocus?: boolean
}

export function isFunction(functionToCheck: any) {
  return functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
}

export const timeAgo = (time: any) => {
  switch (typeof time) {
    case "number":
      break
    case "string":
      time = +new Date(time)
      break
    case "object":
      if (time.constructor === Date) time = time.getTime()
      break
    default:
      time = +new Date()
  }

  const timeFormats = [
    [60, "seconds", 1], // 60
    [120, "1 minute ago", "1 minute from now"], // 60*2
    [3600, "minutes", 60], // 60*60, 60
    [7200, "1 hour ago", "1 hour from now"], // 60*60*2
    [86400, "hours", 3600], // 60*60*24, 60*60
    [172800, "Yesterday", "Tomorrow"], // 60*60*24*2
    [604800, "days", 86400], // 60*60*24*7, 60*60*24
    [1209600, "Last week", "Next week"], // 60*60*24*7*4*2
    [2419200, "weeks", 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, "Last month", "Next month"], // 60*60*24*7*4*2
    [29030400, "months", 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, "Last year", "Next year"], // 60*60*24*7*4*12*2
    [2903040000, "years", 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, "Last century", "Next century"], // 60*60*24*7*4*12*100*2
    [58060800000, "centuries", 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
  ]
  let seconds = (+new Date() - time) / 1000,
    token = "ago",
    list_choice = 1

  if (seconds == 0) {
    return "Just now"
  }
  if (seconds < 0) {
    seconds = Math.abs(seconds)
    token = "from now"
    list_choice = 2
  }
  let i = 0,
    format

  while ((format = timeFormats[i++]))
    if (seconds < +format[0]) {
      if (typeof format[2] == "string") return format[list_choice]
      else return Math.floor(seconds / format[2]) + " " + format[1] + " " + token
    }
  return new Date().getTime()
}

export const compareDates = (val1: any, val2: any, ascending: boolean) => {
  if (val1 === "") {
    return 1
  } else if (val2 === "") {
    return -1
  }
  if (ascending) {
    return new Date(val1)?.getTime() - new Date(val2)?.getTime()
  } else {
    return new Date(val2)?.getTime() - new Date(val1)?.getTime()
  }
}

export const parseDate = (date: string | number | undefined) => {
  if (!date) {
    throw "Invalid date string"
  }

  if (
    (typeof date === "string" || (date as any) instanceof String) &&
    !date.toString().toLowerCase().includes("z") &&
    !date
      .toString()
      .slice(date.toString().length - 6)
      .includes("-")
  ) {
    date += "Z"
  }

  return new Date(date)
}

export const parseDateStr = (date: string | Date | undefined) => {
  if (!date) {
    return ""
  }

  if (typeof date === "string" && !date.toLowerCase().includes("z") && !date.slice(date.length - 6).includes("-")) {
    date += "Z"
  }

  return typeof date === "string" ? new Date(date) : date
}

export const formatDateStr = (date: string | Date | undefined, timeZone?: string) => {
  return `${!!timeZone ? `${timeZone} ` : ""}${parseDateStr(date).toLocaleString("en-US", { timeZone: timeZone })}`
}

export const formatDate = (date: string | Date | undefined) => {
  const formatDateLocal = formatDateStr(date)
  const formatDateUtc = formatDateStr(date, "UTC")
  const dateObj = formatDateStr(date)

  return (
    <Tooltip title={`${formatDateUtc}<br>${timeAgo(dateObj)}`} key={`${formatDateUtc}-${timeAgo(dateObj)}`}>
      <span>{formatDateLocal}</span>
    </Tooltip>
  )
}

function SearchableTable<T>({
  header,
  createButton,
  cancelButton,
  filterButton,
  getRowId,
  data,
  getSearchableContent,
  getSearchableContentExact,
  pageSize: customPageSize,
  actions,
  customForm,
  refresh,
  label,
  onFormSubmit,
  idPrefix,
  customClass,
  isFetching = false,
  mainCheckbox = "checkbox-main",
  defaultSortDirection,
  defaultSortColumn = 0,
  searchDisabled = false,
  paginationDisabled = false,
  fillLine,
  setPageOverride,
  pageOverride,
  pagesOverride,
  totalOverride,
  autoFocus = true,
}: ISearchableTable<T>) {
  defaultSortColumn = Object.values(header).findIndex((col) => !col.disableSorting)

  const searchInputId = joinIds(idPrefix, "search")
  const pageSizeId = joinIds(idPrefix, "pageSize")

  const history = useHistory()
  const newForm = useForm()
  const form = customForm ?? newForm
  const search: string = useWatch({
    control: form.control,
    name: searchInputId, // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: "", // default value before the render
  })
  const pageSize: number = +useWatch({
    control: form.control,
    name: pageSizeId,
    defaultValue: (getLocalStoragePageSize() ?? customPageSize ?? 10).toString(),
  })
  useEffect(() => {
    setLocalStoragePageSize(pageSize)
  }, [pageSize])
  const [sortColumn, setSortColumn] = useState(
    getLocalStorageSortColumn(idPrefix) ?? {
      index: defaultSortColumn,
      ascending: defaultSortDirection ? defaultSortDirection === "ASC" : true,
      key: header[defaultSortColumn]?.getSortKeys?.()?.[0] ?? "",
    },
  )
  useEffect(() => {
    if (
      sortColumn.index === defaultSortColumn &&
      sortColumn.ascending === (defaultSortDirection ? defaultSortDirection === "ASC" : true) &&
      (header[defaultSortColumn]?.getSortKeys?.()?.[0] ?? "" === sortColumn.key)
    ) {
      clearLocalStorageSortColumn(idPrefix)
    } else {
      setLocalStorageSortColumn(idPrefix, sortColumn)
    }
  }, [sortColumn])
  const sortFn = useCallback(
    (one: any, two: any) => {
      const column = header[sortColumn.index]
      const valOne = column.getSortValue?.(one, sortColumn.key) ?? (column.getValue(one) as string) ?? ""
      const valTwo = column.getSortValue?.(two, sortColumn.key) ?? (column.getValue(two) as string) ?? ""

      const compare = column.compare?.(sortColumn.key)
      if (compare) {
        return compare(valOne.toString(), valTwo.toString(), sortColumn.ascending)
      }

      if (sortColumn.ascending) {
        if (typeof valOne !== "number" || typeof valTwo !== "number") {
          return (valOne + "").localeCompare(valTwo + "")
        }
        return valOne - valTwo
      } else {
        if (typeof valOne !== "number" || typeof valTwo !== "number") {
          return (valTwo + "").localeCompare(valOne + "")
        }
        return valTwo - valOne
      }
    },
    [header, sortColumn],
  )

  const findChildText = (header: any, element: PropsWithChildren<ReactNode> | undefined = undefined) => {
    element = element ?? header.title

    if (typeof element === "string" || !React.isValidElement(element)) {
      return element?.toString().toLowerCase().replace(/\s+/g, "-")
    }

    if (isFunction(element)) {
      return header?.getSortKeys?.()?.[0]?.toString().toLowerCase().replace(/\s+/g, "-")
    }

    if (!React.isValidElement(element?.props?.children)) {
      return element?.props?.children?.toString().toLowerCase().replace(/\s+/g, "-")
    }

    findChildText(header, element?.props?.children)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSortColumn((col) => {
        return { ...col }
      })
    }, 50)

    return () => {
      clearInterval(timeout)
    }
  }, [])

  useEffect(() => {
    if (sortColumn.index == -1) {
      return
    }
    if (header[sortColumn.index].disableSorting) {
      setSortColumn((currentColumn) => {
        return { ...currentColumn, index: (currentColumn.index + 1) % header.length }
      })
    }
  }, [sortColumn])

  const { pages, pageItems, filteredItems, setCurrentPage, currentPage } = usePageCount(
    data,
    search,
    pageSize,
    sortColumn.index === -1 ? undefined : sortFn,
    getSearchableContent,
    getSearchableContentExact,
  )
  const page = pageOverride ?? currentPage
  const setPage = setPageOverride ?? setCurrentPage
  const pagesLength = pagesOverride ?? pages

  const mainCheck: any = useWatch({
    control: form.control,
    name: mainCheckbox ?? "n/a",
  })

  useEffect(() => {
    const state = history.location.state as any
    const page = state?.page
    const search = state?.search

    if (search !== undefined) {
      form.setValue(searchInputId, search)
    }

    if (page !== undefined) {
      setCurrentPage(page)
    }
  }, [history])

  useEffect(() => {
    const state = history.location.state as any
    const stateCurrentPage = state?.page ?? 0
    const stateSearch = state?.search ?? ""

    if (stateCurrentPage === page && stateSearch === search) {
      return
    }

    history.replace({
      state: { page: page, search: search },
    })
  }, [search, page])

  useEffect(() => {
    Object.keys(form.getValues())
      .filter((key) => key.startsWith("checkbox"))
      .forEach((key, index: number) => {
        const lastIndex = key.lastIndexOf("-")
        const id = +key.slice(lastIndex + 1)
        if ((filteredItems ?? []).some((item) => getRowId(item, index) === id)) {
          form.setValue(key, mainCheck)
        }
      })
  }, [mainCheck])

  const { portalNode } = useBulkActionPortal()

  return (
    <ThruFormProvider
      customForm={form}
      loading={false}
      preventUnsavedChanges={false}
      onSubmit={(data) => {
        if (onFormSubmit) {
          onFormSubmit(data)
        }
      }}
      renderForm={onFormSubmit ? true : false}
    >
      {(!!createButton || !!filterButton || !searchDisabled || refresh) && (
        <div
          className={cn(`flex justify-between pb-4 pt-4 gap-4`, customClass)}
          style={{
            paddingLeft: fillLine ? "24px" : undefined,
            paddingRight: fillLine ? "24px" : undefined,
          }}
        >
          <div className="col flex-grow justify-between">
            <div className="flex flex-nowrap">
              {label}
              {!searchDisabled && (
                <div className="w-auto">
                  <TextInput
                    name={searchInputId}
                    placeholder="Search"
                    aria-label="Search"
                    style={{ width: "unset", height: "unset", flexGrow: 1, maxWidth: 300 }}
                    autoFocus={autoFocus}
                  />
                </div>
              )}

              {filterButton && filterButton instanceof Array ? (
                filterButton.map((button: any, index: number) => (
                  <div key={index} className="align-self-center">
                    {button}
                  </div>
                ))
              ) : (
                <div className="align-self-center">{filterButton}</div>
              )}
            </div>
          </div>
          <div className={`col flex`} style={{ justifyContent: "flex-end" }}>
            <div className="justify-content-end flex flex-nowrap" style={{ gap: "1.5rem" }}>
              {refresh && (
                <div className="align-self-center">
                  <RefreshIcon isLoading={isFetching} idPrefix={idPrefix} refresh={() => refresh()} />
                </div>
              )}
              {(createButton &&
                createButton instanceof Array &&
                createButton.map((button: any, index: number) => (
                  <div key={index} className="align-self-center">
                    {button}
                  </div>
                ))) ||
                (createButton && <div className="align-self-center">{createButton}</div>)}
              {cancelButton && <ListCancelButton isDirty={form.formState.isDirty} to={cancelButton} />}
            </div>
          </div>
        </div>
      )}

      {actions && (
        <portals.InPortal node={portalNode}>
          <BulkActionsBar form={form} data={data} getRowId={getRowId} actions={actions} />
        </portals.InPortal>
      )}
      <Table
        id={joinIds("tbl", idPrefix)}
        // TODO: customTableClass needed?
        // className={`table ${fillLine ? "" : "table-side-nav"} searchable-table border-b flat-headers ${
        //   customTableClass ? customTableClass : ""
        // }`}
      >
        <TableHeader>
          <TableRow>
            {!pageItems && <TableHead style={{ color: "transparent" }}>Loading</TableHead>}
            {pageItems &&
              header.map((currHeader, index: number) => {
                if (!currHeader.title) {
                  return (
                    <TableHead
                      key={index}
                      scope="col"
                      // TODO: Fix sort headers
                      // className={`sort-header ${currHeader.thClass ?? ""}`}
                      // style={{
                      //   backgroundColor: "transparent",
                      //   position: "relative",
                      //   verticalAlign: "middle",
                      // }}
                    />
                  )
                }

                let innerElement = isFunction(currHeader.title)
                  ? ((currHeader.title as (sortKey: string) => ReactNode | number | null)?.(
                      sortColumn.index === index ? sortColumn.key : currHeader?.getSortKeys?.()?.[0] ?? "",
                    ) as ReactElement)
                  : (currHeader.title as ReactElement)

                if (typeof innerElement === "string" || innerElement instanceof String) {
                  innerElement = <p className="mb-0 inline">{innerElement}</p>
                }

                innerElement = (
                  <innerElement.type
                    {...innerElement.props}
                    ref={currHeader.registerOnForm ? form.register() : undefined}
                    style={{ ...innerElement.props.style }}
                  >
                    {innerElement.props.children}
                  </innerElement.type>
                )

                // TODO: Is this still valid?
                const isInnerElementHeader = innerElement.type === "th"

                const headerProps = isInnerElementHeader ? innerElement.props : {}

                return (
                  <TableHead key={index} {...headerProps} scope="col">
                    {/* Sorting Disabled */}
                    {!!currHeader.disableSorting && (isInnerElementHeader ? innerElement.props.children : innerElement)}
                    {/* Sorting Enabled and No Sort Keys */}
                    {!currHeader.disableSorting && (currHeader.getSortKeys?.().length ?? 0) === 0 && (
                      <Button
                        variant="ghost"
                        onClick={(e) => {
                          if (currHeader.getSortKeys || header[index].disableSorting) return

                          setSortColumn((col) => {
                            if (col.index === index) {
                              return { index: index, ascending: !col.ascending, key: "" }
                            }
                            return { index: index, ascending: true, key: "" }
                          })

                          innerElement.props?.onClick?.(e)
                        }}
                      >
                        {isInnerElementHeader ? innerElement.props.children : innerElement}
                        {!currHeader.disableSorting && (
                          <FontAwesomeIcon
                            key={`insert-${1}-asc`}
                            id={joinIds(idPrefix, "sort-icon-asc", index)}
                            className={`ml-1 text-thru-link`}
                            style={{
                              color: sortColumn.index === index ? undefined : "grey",
                              opacity: sortColumn.index !== index ? 0.6 : 1.0,
                            }}
                            icon={sortColumn.index === index ? (sortColumn.ascending ? faSortUp : faSortDown) : faSort}
                          />
                        )}
                      </Button>
                    )}
                    {/* Sorting Enabled and Sort Keys */}
                    {!currHeader.disableSorting && (currHeader.getSortKeys?.().length ?? 0) !== 0 && (
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <Button
                            variant="ghost"
                            onClick={(e) => {
                              if (currHeader.getSortKeys || header[index].disableSorting) return

                              setSortColumn((col) => {
                                if (col.index === index) {
                                  return { index: index, ascending: !col.ascending, key: "" }
                                }
                                return { index: index, ascending: true, key: "" }
                              })

                              innerElement.props?.onClick?.(e)
                            }}
                          >
                            {isInnerElementHeader ? innerElement.props.children : innerElement}
                            {!currHeader.disableSorting && (
                              <FontAwesomeIcon
                                key={`insert-${1}-asc`}
                                id={joinIds(idPrefix, "sort-icon-asc", index)}
                                className={`ml-1 text-thru-link`}
                                style={{
                                  color: sortColumn.index === index ? undefined : "grey",
                                  opacity: sortColumn.index !== index ? 0.6 : 1.0,
                                }}
                                icon={
                                  sortColumn.index === index ? (sortColumn.ascending ? faSortUp : faSortDown) : faSort
                                }
                              />
                            )}
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          {currHeader.getSortKeys?.().map((sortKey: string, index2: number) => (
                            <DropdownMenuItem
                              key={`${index}-${index2}`}
                              onClick={() => {
                                if (!currHeader.getSortKeys || header[index].disableSorting) return

                                setSortColumn((col) => {
                                  if (col.index === index && sortKey == col.key) {
                                    return { index: index, ascending: !col.ascending, key: sortKey }
                                  }
                                  return { index: index, ascending: true, key: sortKey }
                                })
                              }}
                            >
                              {sortKey}
                            </DropdownMenuItem>
                          ))}
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </TableHead>
                )
              })}
            {pageItems && actions && (
              <TableHead
                className="row-actions"
                scope="col"
                style={{
                  wordWrap: "break-word",
                  backgroundColor: "transparent",
                  textAlign: "center",
                  padding: "0.35rem 0",
                  verticalAlign: "middle",
                }}
              >
                <div style={{ paddingTop: ".6rem", paddingBottom: ".6rem" }}>Actions</div>
              </TableHead>
            )}
          </TableRow>
        </TableHeader>
        <tbody>
          {!pageItems &&
            Array.from({ length: pageSize }).map((item, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    height: "50px",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    borderRadius: "5px",
                  }}
                >
                  <td className="loading align-middle" colSpan={header.length + (actions ? 1 : 0)}>
                    <Skeleton className="align-middle" height="16px" />
                  </td>
                </tr>
              )
            })}
          {pageItems &&
            pageItems.map((item, index) => {
              return (
                <Fade key={getRowId(item, index)}>
                  <TableRow id={joinIds("table", idPrefix, "row", index)}>
                    {header.map((header, index) => {
                      let innerElement = header.getValue(item) as ReactElement

                      if (header.registerOnForm) {
                        innerElement = <innerElement.type {...innerElement.props} ref={form.register()} />
                      }

                      const headerElement = (
                        <TableCell
                          key={index}
                          id={joinIds("table", idPrefix, "row", index, findChildText(header))}
                          className={`align-middle ${header.removeTdPadding ? "remove-padding" : ""} ${
                            header.tdClass ?? ""
                          }`}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: !header.allowOverflow ? "hidden" : undefined,
                            paddingLeft: header.removeTdPaddingLeft ? "0px" : undefined,
                          }}
                        >
                          {innerElement}
                        </TableCell>
                      )

                      if (!header.to) {
                        return headerElement
                      }

                      const headerToLocation = header.to(item)

                      if (!headerToLocation) {
                        return headerElement
                      }

                      return (
                        <TableCell
                          key={index}
                          className={`align-middle ${header.removeTdPadding ? "remove-padding" : ""} ${
                            header.tdClass ?? ""
                          }`}
                          style={{
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: !header.allowOverflow ? "hidden" : undefined,
                            paddingLeft: header.removeTdPaddingLeft ? "0px" : undefined,
                          }}
                        >
                          <StyledLink id={joinIds("action", idPrefix, index)} to={headerToLocation}>
                            {innerElement}
                          </StyledLink>
                        </TableCell>
                      )
                    })}
                    {actions && (
                      <TableCell
                        className="nav-item dropdown actionsDropdown row-actions text-center align-middle"
                        style={{
                          textAlign: "center",
                          fontSize: "1.4rem",
                          wordWrap: "break-word",
                        }}
                      >
                        <div
                          style={{
                            width: "2rem",
                            display: "grid",
                            placeContent: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        >
                          <RowActions
                            generateActions={
                              isFunction(actions)
                                ? (actions as (data: T) => ActionItem<T>[])
                                : () => actions as ActionItem<T>[]
                            }
                            data={item}
                            idPrefix={idPrefix}
                            index={index}
                          />
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                </Fade>
              )
            })}
        </tbody>
      </Table>
      {!paginationDisabled && (
        <div className="pagination-container my-4 flex">
          <p className="mb-0 text-foreground">
            Showing {pageItems?.length ?? 0} of {totalOverride ?? filteredItems?.length ?? 0} items
          </p>
          <div className="d-grid justify-items-center">
            <PaginationBar currentPage={page} setCurrentPage={setPage} pages={pagesLength} />
          </div>
          <div className="flex shrink items-center justify-end">
            <div className="max-w-32 flex items-center">
              <p className="mb-0 mr-2">Show</p>
              <SelectInput
                name={pageSizeId}
                defaultValue={(getLocalStoragePageSize() ?? customPageSize ?? 10).toString()}
              >
                <SelectItem value="10">10</SelectItem>
                <SelectItem value="25">25</SelectItem>
                <SelectItem value="50">50</SelectItem>
                <SelectItem value="100">100</SelectItem>
              </SelectInput>
            </div>
          </div>
        </div>
      )}
    </ThruFormProvider>
  )
}

export default SearchableTable
