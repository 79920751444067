import "react-toastify/dist/ReactToastify.css"
import "./App.css"
import "./styles/thru.css"

import { MantineThemeProvider } from "@/components/mantine-theme-provider"
import { SkeletonThemeProvider } from "@/components/skeleton-theme-provider"
import { ThemeProvider } from "@/components/theme-provider"
import { ToastThemeProvider } from "@/components/toast-theme.provider"
import { useWhiteLabelStore } from "@/components/whitelabel-store"
import { cn } from "@/lib/utils"
import { config } from "@fortawesome/fontawesome-svg-core"
import "@fortawesome/fontawesome-svg-core/styles.css"
import React, { useEffect, useMemo, useState } from "react"
import * as portals from "react-reverse-portal"
import packageJson from "../package.json"
import BulkActionsBarPortal from "./components/bulk-actions/BulkActionsBarPortal"
import CacheBuster from "./components/cache-buster/CacheBuster"
import { DropdownPortal, DropdownProvider } from "./components/dropdown-portal/DropdownPortal"
import Spinner from "./components/spinner/Spinner"
import { ActionProvider } from "./context/ActionContext"
import { useIsAuthenticated } from "./context/AuthContext"
import { BulkActionPortalProvider } from "./context/BulkActionPortalContext"
import { ModalProvider } from "./context/ModalContext"
import { ModalPortalProvider } from "./context/ModalPortalContext"
import { ScrollProvider } from "./context/ScrollContext"
import LoginRoutes from "./screens/login/login-routes/LoginRoutes"
import { useRefreshAccessToken } from "./utils/useRefreshAcessToken"
const globalWindow = window as any

config.autoAddCss = false

const getLocalDateTime = () => {
  const date = new Date()

  return date.toDateString() + " " + date.toLocaleTimeString()
}

const App: React.FC = () => {
  const isAuthenticated = useIsAuthenticated()

  const scrollPortalNode = useMemo(() => portals.createHtmlPortalNode(), [])
  const bulkActionPortalNode = useMemo(() => portals.createHtmlPortalNode(), [])

  useRefreshAccessToken()

  const cycleBrand = useWhiteLabelStore((state) => state.cycleBrand)
  const brand = useWhiteLabelStore((state) => state.brand)

  useEffect(() => {
    const windowAny = window as any
    windowAny.cycleBrand = () => {
      cycleBrand()
    }
  }, [])

  return (
    <>
      <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
        <div className={cn("App app-side", brand)}>
          <portals.OutPortal node={scrollPortalNode} />
          <SkeletonThemeProvider>
            <MantineThemeProvider>
              <BulkActionPortalProvider portalNode={bulkActionPortalNode}>
                <BulkActionsBarPortal />
                <ScrollProvider portalNode={scrollPortalNode}>
                  <ModalPortalProvider>
                    <ActionProvider>
                      <DropdownProvider>
                        <DropdownPortal />
                        <CacheBuster>
                          <>
                            <ToastThemeProvider />
                            <Spinner />

                            <ModalProvider>
                              <React.Suspense fallback={<div className="not-initialized text-center"></div>}>
                                <LoginRoutes />
                              </React.Suspense>
                            </ModalProvider>
                            {isAuthenticated && <Footer />}
                          </>
                        </CacheBuster>
                      </DropdownProvider>
                    </ActionProvider>
                  </ModalPortalProvider>
                </ScrollProvider>
              </BulkActionPortalProvider>
            </MantineThemeProvider>
          </SkeletonThemeProvider>
        </div>
      </ThemeProvider>
    </>
  )
}

const Footer = () => {
  const [time, setTime] = useState({
    local: getLocalDateTime(),
    utc: new Date().toISOString(),
  })

  useEffect(() => {
    const timer = setInterval(() => {
      setTime({ local: getLocalDateTime(), utc: new Date().toLocaleString("en-US", { timeZone: "UTC" }) })
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [])

  return (
    <footer className="fixed left-0 right-0 bottom-0 z-50">
      <div className="flex justify-between">
        <div className="copyright">
          <small>
            {time.local} (UTC {time.utc})
          </small>
        </div>
        <div className="copyright pr-1">
          <small>{`Copyright © ${new Date().getFullYear()} Thru, Inc. (${packageJson.version})`}</small>
        </div>
      </div>
    </footer>
  )
}

export default App
