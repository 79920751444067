import vanillaClient from "@/src/utils/AxiosClient"
import { LoginMotd, loginMotdSchema } from "./LoginMotd"

const _thruAdminLoginMotd = "/ThruAdminLoginMotd"

export const KEY_LIST_LOGIN_MOTD = "thru-admin-login-motd-list"
export const listLoginMotd = () => {
  return vanillaClient(`${_thruAdminLoginMotd}`).then((res) => loginMotdSchema.array().parse(res))
}

export const KEY_GET_LOGIN_MOTD = "thru-admin-login-motd"
export const getLoginMotd = (id: number) => {
  return vanillaClient(`${_thruAdminLoginMotd}/${id}`).then((res) => loginMotdSchema.parse(res))
}
export const createLoginMotd = (data: LoginMotd) => {
  return vanillaClient<LoginMotd>(`${_thruAdminLoginMotd}`, {
    method: "POST",
    body: data,
  })
}

export const updateLoginMotd = (data: LoginMotd) => {
  return vanillaClient<LoginMotd>(`${_thruAdminLoginMotd}`, {
    method: "PUT",
    body: data,
  })
}

export const deleteLoginMotd = (id: number) => {
  return vanillaClient<LoginMotd>(`${_thruAdminLoginMotd}/${id}`, {
    method: "DELETE",
  })
}
