import { faTimes } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { ReactNode } from "react"
import * as portals from "react-reverse-portal"
import { useModalPortal } from "../../context/ModalPortalContext"
import { useCloseModal } from "../../utils/useCloseModal"
import { cn } from "@/lib/utils"

interface IThruModal {
  id: string
  title: ReactNode
  buttons?: ReactNode[]
  modalClass?: string
  modalContentClass?: string
  modalBodyProps?: any
  modalSize?: "sm" | "md" | "lg" | "xl"
  modalFooter?: ReactNode
  children: ReactNode
  scrollable?: boolean
  centered?: boolean
  staticModal?: boolean
  onClose?: () => void
}

const ThruModal: React.FC<IThruModal> = ({
  id,
  title,
  buttons,
  modalClass = "",
  modalContentClass = "",
  modalBodyProps,
  modalSize,
  modalFooter,
  children,
  scrollable,
  centered,
  staticModal,
  onClose,
}: IThruModal) => {
  useCloseModal(id)

  const modalDialogClass = !!modalSize && modalSize !== "md" ? ` modal-${modalSize}` : ""

  const { portalNode } = useModalPortal()

  return (
    <portals.InPortal node={portalNode}>
      <div
        className={`${!!modalClass ? modalClass : ""} modal thru-modal fade`}
        id={id}
        tabIndex={-1}
        role="dialog"
        aria-modal="false"
        data-keyboard={staticModal ? "false" : undefined}
        data-backdrop={staticModal ? "static" : undefined}
        aria-hidden="true"
        style={{ display: "none", paddingRight: 17 }}
      >
        <div
          className={cn("modal-dialog", modalDialogClass, scrollable && "modal-dialog-scrollable", centered && "modal-dialog-centered")}
          role="document"
        >
          <div className={cn("modal-content", modalContentClass)}>
            <div className="modal-header">
              <h1 className="modal-title" id={`${id}-label`}>
                {title}
              </h1>

              <button
                type="button"
                id={`close-${id}-x`}
                className="close flat-button dark"
                data-dismiss={!onClose ? "modal" : undefined}
                aria-label="Close"
                onClick={onClose}
              >
                <FontAwesomeIcon icon={faTimes} size="sm"></FontAwesomeIcon>
              </button>
            </div>
            <div {...modalBodyProps} className={`modal-body p-0 ${modalBodyProps?.className ?? ""}`}>
              {children}
            </div>
            {!!buttons && buttons.length !== 0 && (
              <div className="modal-footer justify-content-start pl-24" style={{ gap: "1rem" }}>
                {React.Children.map(buttons, (button, index) => {
                  return React.cloneElement(button as React.ReactElement, {
                    key: index,
                  })
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </portals.InPortal>
  )
}

export default ThruModal
