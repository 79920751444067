import React, { useEffect } from "react"
import { useModal } from "../../context/ModalContext"
import { useModalHelper } from "../../utils/useModalHelper"
import ModalButton from "../modal-button/ModalButton"
import ThruModal from "../thru-modal/ThruModal"

interface IDiscardModal {
  discardConfirm: () => Promise<void>
}

const MODAL_ID = "discard-confirm"

const DiscardModal: React.FC<IDiscardModal> = ({ discardConfirm }: IDiscardModal) => {
  // removeModal will remove the modal from the DOM
  const { closeModal: removeModal } = useModal()
  const { openModal, closeModal } = useModalHelper({
    modalId: MODAL_ID,
    onHideModal: () => {
      setTimeout(() => {
        removeModal()
      }, 300)
    },
  })

  useEffect(() => {
    openModal()
  }, [])

  return (
    <>
      <ThruModal
        id="discard-confirm"
        title="Discard Changes"
        modalClass="large-font"
        centered
        buttons={[
          <ModalButton
            key={0}
            id="submit-discard"
            variant="default"
            onClick={() => {
              setTimeout(async () => {
                await discardConfirm()
                closeModal()
              }, 100)
            }}
          >
            Discard
          </ModalButton>,
          <ModalButton key={1} id="close-discard-modal">
            Cancel
          </ModalButton>,
        ]}
      >
        <div className="p-6 text-center">
          You have made changes to this item. <br />
          Do you want to discard these changes?
        </div>
      </ThruModal>
    </>
  )
}

export default DiscardModal
