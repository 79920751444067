import { useIsAuthenticated } from "@/src/context/AuthContext"
import React from "react"
import * as portals from "react-reverse-portal"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"
import { useModalPortal } from "../../../context/ModalPortalContext"
import Login from "../Login"
import SetPassword from "../set-password/SetPassword"
import SSOLogin from "../SSOLogin"
const loadAuthenticatedApp = () => import("../../../components/authenticated-app/AuthenticatedApp.jsx")
const AuthenticatedApp = React.lazy(loadAuthenticatedApp)

export interface ILoginRoutes {}

const LoginRoutes: React.FC<ILoginRoutes> = ({}: ILoginRoutes) => {
  const isAuthenticated = useIsAuthenticated()

  React.useEffect(() => {
    loadAuthenticatedApp()
  }, [])

  const { portalNode } = useModalPortal()

  return (
    <>
      <portals.OutPortal node={portalNode} />
      <Router>
        <Switch>
          <Route
            path="/callback/:accessToken/:refreshToken"
            render={(routerProps) =>
              isAuthenticated ? (
                <Redirect to="/" />
              ) : (
                <SSOLogin
                  accessToken={routerProps.match.params.accessToken}
                  refreshToken={routerProps.match.params.refreshToken}
                />
              )
            }
          />
          <Route
            path="/set-password/:key"
            render={(routerProps) => <SetPassword token={routerProps.match.params.key} isReset={false} />}
          />
          <Route
            path="/reset-password/:key"
            render={(routerProps) => <SetPassword token={routerProps.match.params.key} isReset={true} />}
          />
          <Route path="" render={() => (isAuthenticated ? <AuthenticatedApp /> : <Login />)} />
        </Switch>
      </Router>
    </>
  )
}

export default LoginRoutes
