import { Select, SelectContent, SelectTrigger, SelectValue } from "@/components/ui/select"
import { useViewPermission } from "@/src/context/AuthContext"
import { Select as MantineSelect } from "@mantine/core"
import React from "react"
import { Controller, useFormContext, useFormState } from "react-hook-form"
import { useThruFormContext } from "../../context/ThruFormContext"
import SkeletonFormControl from "../skeleton-form-control/SkeletonFormControl"

export interface ISelectInput extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string
  textArea?: boolean
  defaultValue?: string | number
  readOnly?: boolean
  required?: boolean
  children?: React.ReactNode
  loadingOverride?: boolean
  style?: any
  value?: any
  data?: any[]
}

const SelectInput: React.FC<ISelectInput> = ({
  name,
  defaultValue,
  readOnly,
  required = false,
  children,
  loadingOverride,
  style,
  value,
  data,
  ...rest
}: ISelectInput) => {
  const { loading: contextLoading, idPrefix, requiredPermissionsForWrite } = useThruFormContext()
  const viewAllowed = useViewPermission(requiredPermissionsForWrite)

  const loading = loadingOverride ?? contextLoading
  const id = `${idPrefix ?? ""}${name}`
  const { control, register } = useFormContext()
  const { errors } = useFormState({ name: name })
  const error = errors[name]

  const props = {
    ...rest,
    id: id,
    ...(!!data
      ? (register(name, {
          required: { value: required, message: "Please supply a value." },
        }) as any)
      : {}),
    type: "text",
    className: !data ? `form-control${errors[name] ? " validationError" : ""}` : undefined,
    ...(viewAllowed && !!readOnly ? { readOnly: true, disabled: true } : {}),
    "data-toggle": "tooltip",
    style: style,
    value: value,
  }

  return (
    <SkeletonFormControl loading={loading ?? false} inline>
      {!!data && <MantineSelect {...props} required={required} data={data} defaultValue={defaultValue?.toString()} />}
      {!data && (
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field }) => {
            return (
              <Select
                onValueChange={(val) => {
                  field.onChange(val)
                }}
                {...props}
                {...field}
              >
                <SelectTrigger id={`${props.id}-trigger`}>
                  <SelectValue placeholder={props.placeholder} />
                </SelectTrigger>
                <SelectContent className={rest.className}>{children}</SelectContent>
              </Select>
            )
          }}
        />
      )}
      {error && (
        <small id={`error-${id}`} className="form-text danger-color">
          {error.message?.toString()}
        </small>
      )}
    </SkeletonFormControl>
  )
}

export default SelectInput
