import { hasViewPermission } from "@/src/context/AuthContext"
import autoAnimate from "@formkit/auto-animate"
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useEffect, useRef, useState } from "react"
import { useWatch } from "react-hook-form"
import { useQueryClient } from "react-query"
import { getErrorMessage } from "../../utils/ErrorUtils"
import { useModalHelper } from "../../utils/useModalHelper"
import { ActionItem } from "../row-options/RowActions"
import { isFunction, useBulkActions } from "../searchable-table/SearchableTable"
import TailwindLoader from "../tailwind-loader/TailwindLoader"
import Tooltip from "../tooltip/Tooltip"
import { BulkActionsModal } from "./BulkActionsModal"

export function BulkActionsBar<T>({
  form,
  data,
  actions,
  getRowId,
}: {
  form: any
  data?: T[]
  actions: ((data: T) => ActionItem<T>[]) | ActionItem<T>[]
  getRowId: (rowData: T, index: number) => number | string
}) {
  const queryClient = useQueryClient()
  const { openModal } = useModalHelper({ modalId: "bulk-action-id" })

  const formValues = useWatch({
    control: form.control,
    defaultValue: [],
  })

  const [selectedRowsData, setSelectedRowsData] = useState<T[]>([])
  const [selectedAction, setSelectedAction] = useState<ActionItem<T> | null>(null)
  const [tooltipFix, setTooltipFix] = useState(0)

  const selectedRows = Object.entries(formValues)
    .filter(([key, value]) => value && !key.startsWith("checkbox-main") && key.startsWith("checkbox-"))
    .map(([key, value]) => key.split("checkbox-")[1])
    .map((rowId) => parseInt(rowId))
  const previewSelectedRows = selectedRows
    .map((entityId, index) => {
      return data?.find((row) => getRowId(row, index) === entityId) as T
    })
    .filter((row) => row !== undefined)
  const enabledCount = selectedRows.length

  // Tooltip element is not mounted immediately because of the react reverse portal. This is a hack to make the tooltip work.
  useEffect(() => {
    const timeout = setTimeout(() => {
      setTooltipFix(1)
    }, 100)

    return () => clearTimeout(timeout)
  })

  const nodeRef = React.useRef(null)

  const genActions = isFunction(actions) ? (actions as (data: T) => ActionItem<T>[]) : () => actions as ActionItem<T>[]
  const generatedActions = previewSelectedRows.map((row) => {
    return { actions: genActions(row), rowData: row }
  })
  const actionMap = previewSelectedRows.reduce((acc, rowData, index) => {
    return acc.set(
      getRowId(rowData, index),
      genActions(rowData).map((action) => action.title),
    )
  }, new Map<number | string, string[]>())
  const rowHasAction = (rowId: number, actionTitle: string) => {
    return actionMap.get(rowId)?.includes(actionTitle) ?? false
  }

  const actionCount = generatedActions.reduce((acc, currentActions) => {
    currentActions.actions.forEach((action) => {
      acc.set(action.title, [...(acc.get(action.title) ?? []), action])
    })
    return acc
  }, new Map())

  const parent = useRef(null)

  useEffect(() => {
    parent.current && autoAnimate(parent.current)
  }, [parent])

  const setBulkActive = useBulkActions((state) => state.setActive)

  useEffect(() => {
    setBulkActive(enabledCount != 0)
  }, [enabledCount])

  useEffect(() => {
    const valuesToKeep = Array.from(Object.entries(form.getValues())).filter(
      ([key, value]) => !key.startsWith("checkbox-"),
    )
    form.reset(Object.fromEntries(valuesToKeep))
    data?.forEach((flow: any) => {
      form.register(`checkbox-${flow.id}`)
    })
  }, [data])

  const executeRow: (rowData: T) => Promise<{ title: "Success" | "Failed"; message?: string }> = async (rowData: T) => {
    try {
      const res = await selectedAction?.onClick(rowData)
      return { title: "Success" }
    } catch (e) {
      return { title: "Failed", message: getErrorMessage(e) }
    }
  }

  const isDelete = selectedAction?.title.toLowerCase().includes("delete")

  const filteredActions = Array.from(actionCount.entries()).filter(
    ([key, value]) => value[0].bulkSupported && hasViewPermission(value[0].permissionCodes),
  )

  return (
    <>
      <BulkActionsModal
        prompt={() => `Perform bulk ${selectedAction?.title}?`}
        data={selectedRowsData}
        modalClass={isDelete ? "delete-modal" : ""}
        renderRow={({ rowData, outcome, pending, index }) => {
          if (!selectedAction) {
            return <></>
          }

          const state =
            outcome?.title === "Success"
              ? "success"
              : outcome?.title === "Failed"
                ? "error"
                : pending
                  ? "pending"
                  : "ready"

          const renderRow = (state: "success" | "error" | "pending" | "ready") => {
            if (state === "success") {
              return (
                <>
                  <div className="flex flex-grow items-center justify-between">
                    <p className="mb-0 whitespace-nowrap font-bold">{outcome?.title}</p>
                    <div className="flex flex-grow items-center justify-end p-0 w-[25px] h-[25px]">
                      <FontAwesomeIcon icon={faCircleCheck} />
                    </div>
                  </div>
                </>
              )
            } else if (state === "error") {
              return (
                <>
                  <Tooltip title={outcome?.message ?? "Unknown outcome"} dataBoundary="viewport">
                    <p className="mb-0 overflow-hidden overflow-ellipsis whitespace-nowrap font-bold text-red-700">
                      {outcome?.message ?? "Unknown message"}
                    </p>
                  </Tooltip>
                </>
              )
            } else if (state === "pending") {
              return (
                <>
                  <div className="flex flex-grow items-center justify-between">
                    <p className="mb-0 whitespace-nowrap font-bold">Pending...</p>
                    {/* <Loader size="xs" color="red" /> */}
                    <div className="flow-grow flex content-end items-center p-0">
                      <TailwindLoader />
                    </div>
                  </div>
                </>
              )
            } else if (state === "ready") {
              return (
                <>
                  <div className="flex flex-grow items-center justify-between">
                    <p className="mb-0 whitespace-nowrap font-bold">Ready</p>
                    <div className="flex flex-grow content-end items-center p-0 w-[25px] h-[25px]"></div>
                  </div>
                </>
              )
            }
          }

          return (
            <div
              key={index}
              className={`rounded px-6 py-4`}
              style={{
                borderWidth: 1,
                borderColor: isDelete ? "#b91c1c" : "#00B0F0",
                color: isDelete ? "#b91c1c" : "#00B0F0",
                fill: isDelete ? "#b91c1c" : "#00B0F0",
              }}
            >
              <div className="flex items-center">
                <div className="flex min-w-0 flex-grow flex-col">
                  <div className="flex items-center gap-2">
                    {selectedAction?.icon}
                    <p className="mb-0 overflow-hidden overflow-ellipsis whitespace-nowrap font-bold text-muted-foreground">
                      {selectedAction?.getBulkName?.(rowData) ?? (rowData as any).name}
                    </p>
                    <p className="mb-0" style={{ fontSize: "14px" }}>
                      <b>·</b>
                    </p>
                    {renderRow(state)}
                  </div>
                </div>
              </div>
            </div>
          )
        }}
        handleBulkAction={
          !!selectedAction?.handleBulkExport
            ? async (data) => {
                const handle = await selectedAction.handleBulkExport!

                return handle(data)
              }
            : undefined
        }
        executeRow={executeRow}
        onComplete={() => {
          queryClient.invalidateQueries(["list-flow"])
        }}
      />

      <div
        ref={nodeRef}
        className={`${enabledCount === 0 || filteredActions.length === 0 ? "empty" : "present"} ${
          enabledCount === 0 || filteredActions.length === 0 ? "hidden" : ""
        } fixed bottom-0 left-0 right-0 overflow-hidden`}
        style={{
          backgroundColor: "var(--theme-deep-blue)",
          zIndex: 1000,
          transition: "height 0.3s ease",
          paddingLeft: ".6rem",
        }}
      >
        <div
          className="flex flex-wrap items-center gap-6 p-6"
          style={{
            color: "var(--on-theme)",
          }}
          ref={parent}
        >
          <p className="mb-0 whitespace-nowrap font-semibold text-white">{`${enabledCount} Selected`}</p>
          {filteredActions.map(([key, value]) => {
            const action = value[0]

            const button = (
              <div
                key={key}
                className={`v2-flat-button blue-bg flex items-center gap-2 ${
                  value.length !== selectedRows.length ? "disabled" : "color"
                }`}
                onClick={async () => {
                  if (value.length !== selectedRows.length) {
                    selectedRows.forEach((rowId) => {
                      if (!rowHasAction(rowId, action.title)) {
                        form.setValue(`checkbox-${rowId}`, false)
                      }
                    })
                    return
                  }

                  openModal()
                  setSelectedRowsData(
                    selectedRows
                      .map((entityId, index) => {
                        return data?.find((row) => getRowId(row, index) === entityId) as T
                      })
                      .filter((row) => row !== undefined),
                  )
                  setSelectedAction(action)
                }}
              >
                {action.icon}
                {action.title}
                {value.length !== selectedRows.length
                  ? ` (${value.length} / ${selectedRows.length})`
                  : ` (${selectedRows.length})`}
              </div>
            )

            return value.length !== selectedRows.length ? (
              <Tooltip key={action.title} title="Click to only select applicable items.">
                {button}
              </Tooltip>
            ) : (
              button
            )
          })}
        </div>
      </div>
    </>
  )
}
