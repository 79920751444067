import { Button } from "@/components/ui/button"
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Label } from "@/components/ui/label"
import { useWhiteLabelStore } from "@/components/whitelabel-store"
import PasswordInput from "@/src/components/form/PasswordInput"
import TextInput from "@/src/components/form/TextInput"
import { getErrorMessage } from "@/src/utils/ErrorUtils"
import { useSetLoading } from "@/src/utils/useLoader"
import React from "react"
import { useForm } from "react-hook-form"
import { useQuery } from "react-query"
import { Link } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useAuthStore } from "../../context/AuthContext"
import { useSiteSettingOptions } from "../../context/SiteSettingsContext"
import { ThruFormProvider } from "../../context/ThruFormContext"
import { apiUrl } from "../../utils/config"
import { validateEmail } from "../../utils/Validate"
import { KEY_LIST_LOGIN_MOTD, listLoginMotd } from "../thru-admin/thru-admin-login-motd/ThruAdminLoginMotdClient"
import ForgotLoginModal from "./ForgotLoginModal"
import MfaCodeChallange from "./MfaCodeChallange"
import MfaRememberDevice from "./MfaRememberDevice"

const inputs = {
  USERNAME: "username",
  PASSWORD: "password",
}

const Login: React.FC = () => {
  const { handleSubmit: handleSubmitSaml } = useForm()

  const { data: loginMotdList } = useQuery([KEY_LIST_LOGIN_MOTD], () => listLoginMotd())
  const activeMotd =
    loginMotdList?.filter(
      (motd) =>
        window.location.origin.includes(motd.domainName) &&
        (motd.activeStartDate <= new Date() || !motd.activeStartDate) &&
        (motd.activeEndDate >= new Date() || !motd.activeEndDate),
    ) ?? []

  const login = useAuthStore((state) => state.login)
  const authState = useAuthStore((state) => state.authState)
  const mfaCachedCredentials = useAuthStore((state) => state.mfaCachedCredentials)
  const authError = useAuthStore((state) => state.authError)

  const {
    state: { siteSettings },
  } = useSiteSettingOptions()

  const setLoading = useSetLoading()
  const samlIsEnabled = siteSettings?.data?.samlLoginIsEnabled

  const onSubmit = async (data: any) => {
    const credentials = {
      username: data[inputs.USERNAME],
      password: data[inputs.PASSWORD],
    }

    setLoading(true)
    await login(credentials)
    setLoading(false)
  }

  const onSamlSubmit = async (data: any) => {
    const samlSSOurl = `${apiUrl}/saml/InitiateSingleSignOn`
    window.location.replace(samlSSOurl)
  }

  const loginRef = React.useRef(null)
  const challengeAuthRef = React.useRef(null)
  const rememberDeviceRef = React.useRef(null)
  const nodeRef: any =
    authState === "mfa-code-sent" ? loginRef : authState === "mfa-code-verified" ? rememberDeviceRef : challengeAuthRef

  const brand = useWhiteLabelStore((state) => state.brand)

  return (
    <div className="thru-login-container m-0 flex p-0" style={{ display: "flex", flexWrap: "wrap" }}>
      <div
        className="left thru-grid-item flex flex-col justify-between"
        id="first-grid-item"
        style={{ display: "flex", flexBasis: "min(400px, 100vw)", flexGrow: 1, flexShrink: 1 }}
      >
        <div style={{ padding: "0rem 2rem" }}>
          {brand === "thru" && (
            <img className="img-fluid" src="\images\whitelogo_registered.svg" alt="Thru logo" width={200} />
          )}
          {brand === "boomi" && (
            <img className="img-fluid" src="\images\boomi-logo-hp.svg" alt="Thru logo" width={200} />
          )}
        </div>
        <div className="thru-login-centerTitles justify-center">
          <h3 className="thru-login-title">Automated File Transfer</h3>
          <h4 className="thru-login-subtitle">
            Thru is the leading provider of cloud-native secure file transfer solutions. Since 2002, we have offered
            market-first technologies designed to tackle the most demanding and complex file transfer challenges.
          </h4>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img className="img-fluid" src="\images\login-ui_2.svg" alt="Homepage Preview" />
          </div>
        </div>

        <div className="thru-login-footer">
          <div className="thru-login-info">
            <div className="thru-login-copyright">
              <small>Copyright © {new Date().getFullYear()} Thru, Inc.</small>
            </div>
            <div className="thru-login-menu">
              <a target="_blank" rel="noreferrer" href="https://www.thruinc.com/support" className="thru-link">
                Support
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.thruinc.com/contact-us/" className="thru-link">
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex w-full flex-col items-center justify-center bg-source/20 py-8"
        style={{ position: "relative", flexBasis: "min(400px, 100vw)", flexGrow: 1 }}
      >
        <ForgotLoginModal
          modalId="forgotUsernameModal"
          title="Recover Your Username"
          prompt={`Enter the email associated with your account and we'll send you a reminder.`}
          inputLabel="Email"
          inputPlaceholder="Enter your email"
          validate={(value: any) => (!validateEmail(value) ? "Invalid email format." : true)}
        />
        <ForgotLoginModal
          modalId="forgotPasswordModal"
          title="Recover Your Password"
          prompt={`Enter your username and we'll send you a link to reset your password.`}
          inputLabel="Username"
          inputPlaceholder="Enter your username"
        />
        <div
          className="si-bg"
          style={{
            width: "100%",
            height: "min(100%, 600px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="thru-login-form-mobile" style={{ zIndex: 1 }}>
            <Card className="mx-auto min-w-[360px] max-w-sm overflow-hidden py-6 px-2">
              <SwitchTransition mode={"out-in"}>
                <CSSTransition
                  key={authState}
                  nodeRef={nodeRef}
                  addEndListener={(done: any) => {
                    nodeRef.current.addEventListener("transitionend", done, false)
                  }}
                  classNames="fade"
                >
                  <div ref={nodeRef}>
                    <div className="login-container">
                      {authState === "mfa-code-sent" && mfaCachedCredentials ? (
                        <MfaCodeChallange
                          username={mfaCachedCredentials.username}
                          password={mfaCachedCredentials.password}
                          sid={mfaCachedCredentials.sid}
                          email={mfaCachedCredentials.email}
                        />
                      ) : authState === "mfa-code-verified" ? (
                        <MfaRememberDevice />
                      ) : (
                        <>
                          <CardHeader className="text-center">
                            <CardTitle className="text-3xl">Login</CardTitle>
                          </CardHeader>
                          <CardContent>
                            <div className="grid gap-4">
                              <ThruFormProvider onSubmit={onSubmit} loading={false} preventUnsavedChanges={false}>
                                <div className="grid gap-4">
                                  <div className="grid gap-2">
                                    <div className="flex items-center">
                                      <Label htmlFor={inputs.PASSWORD}>Username</Label>
                                      <Link
                                        to="#"
                                        className="ml-auto inline-block text-sm underline"
                                        data-toggle="modal"
                                        data-target="#forgotUsernameModal"
                                        id="forgot-username-button"
                                        tabIndex={6}
                                      >
                                        Forgot username?
                                      </Link>
                                    </div>
                                    <TextInput
                                      autoFocus
                                      name={inputs.USERNAME}
                                      placeholder="m@example.com"
                                      required
                                      tabIndex={1}
                                    />
                                  </div>
                                  <div className="grid gap-2">
                                    <div className="flex items-center">
                                      <Label htmlFor={inputs.PASSWORD}>Password</Label>
                                      <Link
                                        to="#"
                                        className="ml-auto inline-block text-sm underline"
                                        data-toggle="modal"
                                        data-target="#forgotPasswordModal"
                                        id="forgot-password-button"
                                        tabIndex={7}
                                      >
                                        Forgot password?
                                      </Link>
                                    </div>
                                    <PasswordInput name={inputs.PASSWORD} required newPassword={false} tabIndex={2} />
                                  </div>

                                  <Button id="btnLogin" type="submit" className="w-full" tabIndex={4}>
                                    Login
                                  </Button>
                                </div>
                              </ThruFormProvider>

                              {!!authError && <p className="text-sm text-red-600">{getErrorMessage(authError)}</p>}

                              {samlIsEnabled ? (
                                <form key={2} onSubmit={handleSubmitSaml(onSamlSubmit)}>
                                  <div className="relative mb-4">
                                    <div className="absolute inset-0 flex items-center">
                                      <span className="w-full border-t" />
                                    </div>
                                    <div className="relative flex justify-center text-xs uppercase">
                                      <span className="bg-background px-2 text-muted-foreground">
                                        SSO Corporate Login
                                      </span>
                                    </div>
                                  </div>
                                  <Button
                                    id="btnSamlLogin"
                                    type="submit"
                                    variant="outline"
                                    className="w-full"
                                    tabIndex={5}
                                  >
                                    Corporate Login
                                  </Button>
                                </form>
                              ) : (
                                <></>
                              )}
                            </div>
                          </CardContent>
                        </>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Card>
            {/* TODO: Is this needed? */}
            {/* <div className="thru-login-form">
              <SwitchTransition mode={"out-in"}>
                <CSSTransition
                  key={authState}
                  nodeRef={nodeRef}
                  addEndListener={(done: any) => {
                    nodeRef.current.addEventListener("transitionend", done, false)
                  }}
                  classNames="fade"
                >
                  <div ref={nodeRef}>
                    <div className="login-container">
                      {authState === "mfa-code-sent" && mfaCachedCredentials ? (
                        <MfaCodeChallange
                          username={mfaCachedCredentials.username}
                          password={mfaCachedCredentials.password}
                          sid={mfaCachedCredentials.sid}
                          email={mfaCachedCredentials.email}
                        />
                      ) : authState === "mfa-code-verified" ? (
                        <MfaRememberDevice />
                      ) : (
                        <>
                          <h3 className="thru-signin-title">Login</h3>
                          <ThruFormProvider onSubmit={onSubmit} loading={false}>
                            <div className="thru-login-v2 login-form-tweak mb-4 mt-6">
                              <div className="mb-4 ">
                                <div className="form-horizontal">
                                  <input
                                    type="hidden"
                                    id="TenantId"
                                    name="TenantId"
                                    className="form-control"
                                    data-toggle="tooltip"
                                  />
                                  <div className="mb-4">
                                    <TextInputLabel label="Username" autoFocus name={inputs.USERNAME} required />
                                  </div>
                                  <div className="mb-4">
                                    <PasswordInputLabel
                                      label="Password"
                                      name={inputs.PASSWORD}
                                      required
                                      newPassword={false}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="thru-login__actions mt-6">
                                <Button id="btnLogin" className="w-full" size="lg" type="submit">
                                  Login
                                </Button>
                              </div>
                              {!!authError && (
                                <div className="alert alert-danger mb-0 mt-4">{getErrorMessage(authError)}</div>
                              )}
                              <div className="thru-login-essentials mt-6 flex justify-center">
                                <div className="mr-5">
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#forgotUsernameModal"
                                    id="forgot-username-button"
                                  >
                                    Forgot Username?
                                  </a>
                                </div>
                                <div className="ml-5">
                                  <a
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#forgotPasswordModal"
                                    id="forgot-password-button"
                                  >
                                    Forgot Password?
                                  </a>
                                </div>
                              </div>
                            </div>
                            {activeMotd?.length > 0 && (
                              <div
                                className="thru-login__divider"
                                style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
                              >
                                <div className="thru-divider">
                                  <span />
                                  <span>Messages</span>
                                  <span />
                                </div>
                                <div className="thru-login-motd mt-3 flex flex-col gap-4 text-center">
                                  {activeMotd.map((motd) => (
                                    <div
                                      key={motd.id}
                                      className="thru-login-motd-item"
                                      style={{ color: motd.textColor }}
                                    >
                                      <span className="thru-login-motd-message">{motd.message}</span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </ThruFormProvider>
                          {samlIsEnabled ? (
                            <form key={2} onSubmit={handleSubmitSaml(onSamlSubmit)}>
                              <div
                                className="thru-login__divider"
                                style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
                              >
                                <div className="thru-divider">
                                  <span />
                                  <span>SSO Corporate Login</span>
                                  <span />
                                </div>
                              </div>
                              <Button id="btnSamlLogin" type="submit" className="w-full" size="lg">
                                Corporate Login
                              </Button>
                            </form>
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
