import { ReactNode, useState } from "react"
import { useModalHelper } from "../../utils/useModalHelper"
import ModalButton from "../modal-button/ModalButton"
import ThruModal from "../thru-modal/ThruModal"

type IBulkActionsModal<T, T2> = {
  data: T[]
  prompt: (data: T[]) => ReactNode
  renderRow: ({
    rowData,
    outcome,
    pending,
    index,
  }: {
    rowData: T
    outcome?: T2
    pending: boolean
    index: number
  }) => ReactNode
  executeRow: (data: T) => Promise<T2>
  handleBulkAction?: (data: T[]) => Promise<T2[]>
  onComplete?: () => any
  modalClass?: string
}

export function BulkActionsModal<T, T2>({
  data,
  renderRow,
  prompt,
  executeRow,
  onComplete,
  handleBulkAction,
  modalClass,
}: IBulkActionsModal<T, T2>) {
  const [outcome, setOutcome] = useState<Map<T, T2>>(new Map())
  const [pendingIndex, setPendingIndex] = useState<number>(-1)
  const [executionComplete, setExecutionComplete] = useState(false)

  useModalHelper({
    modalId: "bulk-action-id",
    onHideModal: () => {
      setOutcome(new Map())
      setPendingIndex(-1)
      setExecutionComplete(false)
    },
  })

  return (
    <ThruModal
      modalSize="lg"
      id="bulk-action-id"
      modalClass={modalClass}
      title={prompt(data)}
      buttons={
        !executionComplete
          ? [
              <ModalButton
                id="bulk-action-confirm"
                key={0}
                variant={modalClass?.includes("delete-modal") ? "destructive_border" : "default"}
                onClick={async () => {
                  if (!!handleBulkAction) {
                    setPendingIndex(-2)
                    const newOutcome = await handleBulkAction(data)
                    setPendingIndex(-1)

                    setOutcome((oldOutcome) => {
                      newOutcome.forEach((outcome, index) => {
                        oldOutcome?.set(data[index], outcome)
                      })

                      return new Map(oldOutcome)
                    })
                  } else {
                    for (const rowData of data) {
                      // Set pending status
                      setPendingIndex(data.indexOf(rowData))

                      const newOutcome = await executeRow(rowData)

                      setPendingIndex(-1)
                      setOutcome((oldOutcome) => {
                        oldOutcome?.set(rowData, newOutcome)

                        return new Map(oldOutcome)
                      })
                    }
                  }
                  onComplete?.()
                  setExecutionComplete(true)
                }}
              >
                Continue
              </ModalButton>,
              <ModalButton id="bulk-action-cancel" key={1}>
                Cancel
              </ModalButton>,
            ]
          : [
              <ModalButton id="bulk-action-close" key={2}>
                Close
              </ModalButton>,
            ]
      }
    >
      <div
        className="flex flex-col gap-3 overflow-auto"
        style={{
          padding: "1.5rem 24px",
          maxHeight: "50vh",
        }}
      >
        {data.map((rowData, index) => {
          // -2 is a magic constant for all entries pending. At the time of implementation this is for the handleBulkExport optional
          // function for row actions.
          return renderRow({
            rowData,
            outcome: outcome.get(rowData),
            pending: pendingIndex === index || pendingIndex === -2,
            index,
          })
        })}
      </div>
    </ThruModal>
  )
}
