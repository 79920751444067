import { useLayoutEffect } from "react"
const globalWindow = window as any

function useCloseModal(modalName: string) {
  useLayoutEffect(() => {
    return () => {
      globalWindow.$(`#${modalName}`).modal("hide")
    }
  }, [modalName])
}

export { useCloseModal }
