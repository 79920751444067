import { useCallback, useLayoutEffect } from "react"
const $ = (window as any).$

interface IModalHelper {
  modalId: string
  onOpenModal?: () => void
  onHideModal?: () => void
}

export const useModalHelper = ({ modalId, onOpenModal, onHideModal }: IModalHelper) => {
  const openModal = useCallback(() => {
    $(`#${modalId}`).modal("show")
  }, [modalId])
  const closeModal = useCallback(() => {
    $(`#${modalId}`).modal("hide")
  }, [modalId])

  // Trigger on open modal notification
  useLayoutEffect(() => {
    $(`#${modalId}`).on("shown.bs.modal", () => {
      onOpenModal?.()
    })

    return () => {
      $(`#${modalId}`).unbind("shown.bs.modal")
    }
  }, [onHideModal, modalId, onOpenModal])

  // Trigger on hide modal notification
  useLayoutEffect(() => {
    $(`#${modalId}`).on("hide.bs.modal", () => {
      onHideModal?.()
    })

    return () => {
      $(`#${modalId}`).unbind("hide.bs.modal")
    }
  }, [onHideModal, modalId, onHideModal])

  return { openModal, closeModal }
}
