import { Button } from "@/components/ui/button"
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card"
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form"
import { InputOTP, InputOTPGroup, InputOTPSlot } from "@/components/ui/input-otp"
import { useSetLoading } from "@/src/utils/useLoader"
import React, { useCallback, useMemo, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"
import { toast } from "react-toastify"
import { useAuthStore } from "../../context/AuthContext"
import { resendCode } from "../../utils/AuthClient"
import { getErrorMessage } from "../../utils/ErrorUtils"

export type IMfaCodeChallange = {
  username: string
  password: string
  sid: string
  email: string
}

const inputs = {
  MFA_CODE: "mfaCode",
}

const MfaCodeChallange: React.FC<IMfaCodeChallange> = ({ username, password, sid, email }) => {
  const form = useForm()
  const verifyMfaCode = useAuthStore((state) => state.verifyMfaCode)
  const logout = useAuthStore((state) => state.logout)
  const setLoading = useSetLoading()
  const [_, setUpdate] = React.useState(0)
  const [lastCode, setLastCode] = React.useState(new Date().getTime())
  const authError = useAuthStore((state) => state.authError)

  // Trigger re-render every second
  React.useEffect(() => {
    const interval = setInterval(() => {
      setUpdate((prev) => prev + 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const onSubmit = useCallback(
    async (data: any) => {
      setLoading(true)
      await verifyMfaCode(data[inputs.MFA_CODE])
      setLoading(false)
    },
    [username, password],
  )

  const [resendCodeError, setResendCodeError] = useState<string>("")

  const doResendCode = useMutation(
    ({ sid, email }: { sid: string; email: string }) => {
      return resendCode(sid, email)
    },
    {
      onMutate: async (newOrg) => {
        setLoading(true)
      },
      onError: (err, newOrg, context: any) => {
        setResendCodeError(getErrorMessage(err))
      },
      onSuccess: (data, organizationId, context) => {
        setResendCodeError("")
        setLastCode(new Date().getTime())
        toast.success("Successfully sent activation code.")
      },
      onSettled: (data, error, variables, context) => {
        setLoading(false)
      },
    },
  )

  const secondsLeft = useMemo(() => {
    const now = new Date().getTime()
    const diff = now - lastCode
    const seconds = Math.floor(diff / 1000)
    return 30 - seconds
  }, [lastCode, _])

  return (
    <>
      <CardHeader>
        <CardTitle className="text-2xl">{username}</CardTitle>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name={inputs.MFA_CODE}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>One-Time Password</FormLabel>
                  <FormControl>
                    <InputOTP maxLength={6} {...field}>
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormDescription>Please enter the one-time password sent to your email.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-col gap-2">
              <Button type="submit" className="w-full" size="lg">
                Verify
              </Button>
              <Button
                id="btnResendCode"
                className="w-full"
                variant="outline"
                type="button"
                size="lg"
                disabled={secondsLeft > 0}
                onClick={async () => await doResendCode.mutateAsync({ sid, email })}
              >
                {secondsLeft > 0 ? <span>Resend Code in {secondsLeft} seconds</span> : <span>Resend Code</span>}
              </Button>
            </div>
            {authError && <div className="alert alert-danger mb-0">{getErrorMessage(authError)}</div>}
            <a
              className="block text-center text-thru-primary hover:text-accent-thru-primary hover:underline"
              href="#"
              id="cancel-sign-in"
              onClick={() => {
                logout({})
              }}
            >
              Cancel signing in
            </a>
          </form>
        </Form>
      </CardContent>
    </>
  )
}

export default MfaCodeChallange
