import React, { ReactElement, useLayoutEffect, useRef, useState } from "react"
import { v4 as uuidv4 } from "uuid"
const globalWindow = window as any

interface ITooltip {
  title: string
  showDelay?: number
  hideDelay?: number
  showOnFocus?: boolean
  children: ReactElement
  overflowOnly?: boolean
  dataBoundary?: string
  dataHtml?: boolean
}

const TooltipWrapper: React.FC<ITooltip & { tooltipEnabled?: boolean }> = ({ tooltipEnabled = true, ...rest }) => {
  if (!tooltipEnabled) {
    return rest.children
  }

  return <Tooltip {...rest}></Tooltip>
}

const Tooltip: React.FC<ITooltip> = ({
  title,
  showDelay = 100,
  hideDelay = 0,
  showOnFocus = false,
  children,
  overflowOnly = false,
  dataBoundary = "scrollParent",
  dataHtml = true,
}: ITooltip) => {
  const ref = useRef(null)
  const [id, setId] = useState(children?.props?.id ?? uuidv4())
  const [overflowing, setOverflowing] = useState(false)
  useLayoutEffect(() => {
    if (overflowOnly && !overflowing) {
      return
    }

    if (showOnFocus) {
      globalWindow.$(`#${id}`).tooltip()
    } else {
      globalWindow.$(`#${id}`).tooltip({ trigger: "hover" })
    }

    return () => {
      globalWindow.$(`#${id}`).tooltip("dispose")
    }
  }, [overflowOnly, overflowing])

  if (!children) {
    return <></>
  }

  const safeChild = typeof children === "string" ? <p className="mb-0">{children}</p> : children

  return React.cloneElement(safeChild, {
    id: id,
    ref: ref,
    title: !(overflowOnly && !overflowing) ? title : undefined,
    onMouseEnter: () => {
      const currentRef: any = ref.current
      if (currentRef?.offsetWidth > currentRef?.parentNode?.parentNode?.offsetWidth) {
        if (!overflowing) {
          setOverflowing(true)
        }
      } else if (overflowing) {
        setOverflowing(false)
      }
    },
    ["data-html"]: dataHtml,
    ["data-toggle"]: "tooltip",
    ["data-placement"]: "top",
    ["data-boundary"]: dataBoundary,
    ["data-delay"]: `{ "show": ${showDelay}, "hide": ${hideDelay} }`,
  })
}

export default TooltipWrapper
