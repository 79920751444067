import React, { useEffect } from "react"
import { useModal } from "../../context/ModalContext"
import { useModalHelper } from "../../utils/useModalHelper"
import ModalButton from "../modal-button/ModalButton"
import ThruModal from "../thru-modal/ThruModal"

interface IDeleteModal {
  doDelete: () => any
  entityTypeName?: string
  entityName?: string
}

const DELETE_MODAL_ID = "delete-confirm"

const DeleteModal: React.FC<IDeleteModal> = ({ entityTypeName, doDelete, entityName }: IDeleteModal) => {
  const { closeModal } = useModal()
  const { openModal } = useModalHelper({
    modalId: DELETE_MODAL_ID,
    onHideModal: () => {
      closeModal()
    },
  })

  useEffect(() => {
    openModal()
  }, [])

  return (
    <>
      <ThruModal
        modalClass="delete-modal"
        centered
        id={DELETE_MODAL_ID}
        modalSize="md"
        title={`Delete ${entityTypeName}`}
        buttons={[
          <ModalButton
            id="delete-modal-confirm"
            variant="destructive_border"
            key={0}
            onClick={() => {
              setTimeout(async () => {
                try {
                  await doDelete()
                } catch (e) {}
                closeModal()
              }, 100)
            }}
          >
            Delete
          </ModalButton>,
          <ModalButton id="close-delete-modal" key={1}>
            Cancel
          </ModalButton>,
        ]}
      >
        <div className="modal-body" style={{ padding: 24, lineBreak: "anywhere" }}>
          Once you delete this {entityTypeName}, it cannot be restored.
          <br />
          <br />
          Are you sure you want to delete {entityTypeName} <b>{entityName}</b>?
        </div>
      </ThruModal>
    </>
  )
}

export default DeleteModal
