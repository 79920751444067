export function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export function validateRegex(regex: string) {
  const expressions = [regex]

  const messages = expressions
    .map((exp: string, index: number) => {
      try {
        new RegExp(exp)
      } catch (e: any) {
        return e.message
      }
      return undefined
    })
    .filter((message: string | undefined) => !!message)

  if (messages.length > 0) {
    return messages.join("<br/>")
  }

  return true
}

export function validatePath(path: string, specialChars?: string) {
  if (path.match(/^\s*$/g)) {
    return "Path cannot be empty"
  }

  if (!path.match(/^[\/\\]/)) {
    return 'Path must start with a "/" or "\\".'
  }

  if (specialChars == (undefined || null)) specialChars = `:\*\?\"<>\|`

  const pathFloatingSpaceCheck = new RegExp(/(^\s)|(\s[\\\/])|([\\\/]\s)/g)
  if (pathFloatingSpaceCheck.test(path)) {
    return "Spaces cannot be directly before or after a slash."
  }

  const regExString = `/[${specialChars}]|\.\./gm`
  const blacklistCheck = new RegExp(regExString)

  if (blacklistCheck.test(path)) {
    const invalidChars = path.match(blacklistCheck)?.filter((value, index, self) => self.indexOf(value) === index)

    if (!invalidChars) {
      return "Invalid path"
    }

    return `Please remove the following invalidate characters: ${invalidChars.map((char) => `"${char}"`).join(", ")}`
  }

  const whitelistCheck = new RegExp(/^([\p{L} -_~])+$/gu)

  if (!whitelistCheck.test(path)) {
    const whitelistRemove = new RegExp(/([\p{L} -_~])+/gu)
    const invalidChars = path.replace(whitelistRemove, "")

    return `Please remove the following invalidate characters: ${Array.from(invalidChars).join(", ")}`
  }

  return true
}

export function validateUsername(username: string) {
  if (new RegExp("^[A-Za-z0-9_-]*$").test(username)) {
    return true
  }

  return "Usernames must only consist of alphanumeric letters, underscore (_), and dash (-)."
}
